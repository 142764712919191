.unauthorized-alert {
  position: fixed;
  top: 0;
  left: 40%;
  width: 80%;
  max-width: 350px;
  animation: slide-in 0.3s ease-in-out forwards;
  z-index: 9999;
}

@keyframes slide-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0, 70%);
  }
}
