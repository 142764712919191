div.alert-setting {
  input {
    width: 50px;
    text-align: center;
  }
}

.inline-content {
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.alert-reminder-row {
  gap: 10px;
  flex-direction: column;
  display: flex;

  button {
    align-self: start;
    width: 80px;
  }
}

.alert-details-tabs {
  font-size: medium;
}

.stateful-incident-settings {
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .setting-name {
    font-style: italic;
  }
  .setting-container {
    margin-left: 2rem;
    margin-bottom: 1.5rem;
  }
}

div.notification-rules-section {
  .description {
    font-size: 16px;
  }
  .hint {
    font-size: small;
  }
}
